












































































































import { Component, Vue } from "vue-property-decorator";
import MaterialSelect from "@/components/material-select/index.vue";
import {
    apiGoodsCategoryAdd,
    apiGoodsCategoryDetail,
    apiGoodsCategoryEdit,
    apiGoodsCategoryLists,
} from "@/api/goods";
@Component({
    components: {
        MaterialSelect,
    },
})
export default class AddCategory extends Vue {
    $refs!: { form: any };
    id!: any;
    loading = false;
    hasPid = 0;
    categoryList = [];
    form: any = {
        name: "",
        pid: "",
        image: "",
        sort: 1,
        is_show: 1,
        is_recommend: 0,
        packge_id: "",
    };

    rules = {
        name: [
            {
                required: true,
                message: "请输入分类名称",
                trigger: ["blur", "change"],
            },
        ],
        image: [
            {
                required: true,
                message: "请选择分类图标",
                trigger: ["blur", "change"],
            },
        ],
    };

    handleSave() {
        this.$refs.form.validate((valid: boolean) => {
            if (valid) {
                if (!this.hasPid) {
                    this.form.pid = "";
                }
                this.form.packge_id = this.$route.query.packge_id;
                const api = this.id
                    ? apiGoodsCategoryEdit(this.form)
                    : apiGoodsCategoryAdd(this.form);
                api.then(() => {
                    this.$router.push({
                        path: "/goods/operation_details/category_manage",
                        query: { packge_id: this.$route.query.packge_id },
                    });
                    //   this.$router.go(-1);
                });
            } else {
                return false;
            }
        });
    }

    getGoodsCategoryDetail() {
        this.loading = true;
        apiGoodsCategoryDetail({ id: this.id }).then((res: any) => {
            if (res.pid) {
                this.hasPid = 1;
            }
            this.form = res;
            this.loading = false;
        });
    }

    getGoodsCategoryLists() {
        apiGoodsCategoryLists({ packge_id: this.$route.query.packge_id }).then(
            (res: any) => {
                res?.lists.forEach((item: any) => {
                    item.sons &&
                        item.sons.forEach((sitem: any) => {
                            delete sitem.sons;
                        });
                });
                this.categoryList = res?.lists;
            }
        );
    }

    created() {
        this.id = this.$route.query.id;
        this.id && this.getGoodsCategoryDetail();
        this.getGoodsCategoryLists();
    }
}
